<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
        class=" space-y-4 pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh; background-color: #0E153A" 
      >
        <div class="block space-y-10">
            <h1 class="block text-white text-4xl font-bold playfair">
                Thank you for subscribing 🎉 
            </h1>
            <p class="block mt-5 text-white text-xl font-bold roboto"> Have a cookie 🍪 </p>
        </div>
      </div>
    </main>
    <footer-component class="pt-48"></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
