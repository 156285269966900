<template>
  <div>
    <div
      class="min-w-screen min-h-screen px-5 py-5"
      style="background-color: #0e153a"
    >
      <div class="w-full mx-auto px-5 py-10 text-white mb-10">
        <div class="text-center max-w-xl mx-auto pb-24">
          <h1 class="text-5xl md:text-6xl font-bold mb-5 playfair">Pricing</h1>
          <h3 class="text-xl font-medium mb-10 roboto">
            Afordable pricing schemes for every use case.
          </h3>
        </div>
        <div class="w-full md:flex mb-5 text-black">
          <div
            class="w-full rounded md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-6 rounded-md shadow-2xl shadow-gray-600 md:flex md:flex-col"
          >
            <div class="w-full flex-grow">
              <h2 class="text-center font-bold uppercase mb-4 roboto">PERSONAL</h2>
              <h3 class="text-center font-bold text-4xl mb-2 roboto">
                $4.99<span class="text-lg roboto">/mo</span>
              </h3>
              <p class="text-center font-bold mb-5">
                <a
                  href="#"
                  class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block roboto"
                  >Read more<i class="mdi mdi-arrow-right-thick ml-1"></i
                ></a>
              </p>
              <ul class="">
                <li class="mt-10 flex">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    50,000 API requests
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Unlimited number of projects
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Tokenization
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Cancel anytime
                  </p>
                </li>
              </ul>
            </div>
            <div class="w-full mt-20">
              <button
                class="shadow-2xl rounded font-bold hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full roboto"
                style="background-color: #3d5af1"
              >
                Buy Now
              </button>
            </div>
          </div>
          <div
            class="w-full rounded md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:my-3 rounded-md shadow-2xl shadow-gray-600 md:relative md:z-50 md:flex md:flex-col"
          >
            <div class="w-full flex-grow">
              <h2 class="text-center font-bold uppercase mb-4 roboto">TEAM</h2>
              <h3 class="text-center font-bold text-3xl md:text-4xl mb-2 roboto">
                $99<span class="text-lg roboto">/mo</span>
              </h3>
              <p class="text-center font-bold mb-5">
                <a
                  href="#"
                  class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block roboto"
                  >Read more<i class="mdi mdi-arrow-right-thick ml-1"></i
                ></a>
              </p>
              <ul class="">
                <li class="mt-10 flex">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    1,000,000 API requests
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Unlimited number of projects
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Tokenization
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    5 collaborators
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200 roboto"
                  >
                    Cancel anytime
                  </p>
                </li>
              </ul>
            </div>
            <div class="w-full mt-20">
              <button
                class="shadow-2xl rounded font-bold hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full roboto"
                style="background-color: #3d5af1"
              >
                Buy Now
              </button>
            </div>
          </div>
          <div
            class="roboto shadow-2xl text-white rounded w-full md:w-1/4 md:max-w-none px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:mb-0 rounded-md shadow-lg shadow-gray-600 md:relative md:z-50 md:flex md:flex-col"
            style="background-color: #3d5af1"
          >
            <div class="w-full flex-grow">
              <h2 class="text-center font-bold uppercase mb-4 roboto">PRO</h2>
              <h3 class="text-center font-bold text-4xl md:text-5xl mb-2 roboto">
                $199<span class="text-lg roboto">/mo</span>
              </h3>
              <p class="text-center font-bold mb-5">
                <a
                  href="#"
                  class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block roboto"
                  >Read more<i class="mdi mdi-arrow-right-thick ml-1"></i
                ></a>
              </p>
              <ul class="">
                <li class="mt-10 flex">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">5,000,000 API requests</p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">
                    Unlimited number of projects
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">Tokenizations</p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">10 collaborators</p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">Same Day Support</p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 text-base leading-6">Cancel anytime</p>
                </li>
              </ul>
            </div>
            <div class="w-full mt-20">
              <button
                class="font-bold hover:bg-gray-700 text-black rounded px-10 py-2 transition-colors w-full shadow-2xl"
                style="background-color: #22d1ee"
              >
                Buy Now
              </button>
            </div>
          </div>
          <div
            class="roboto w-full rounded md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-3 rounded-md shadow-2xl shadow-gray-600 md:flex md:flex-col"
          >
            <div class="w-full flex-grow">
              <h2 class="text-center text-3xl font-bold uppercase mb-2">
                ENTERPRISE
              </h2>
              <p class="text-center font-bold mb-5">
                <a
                  href="#"
                  class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block"
                  >Read more<i class="mdi mdi-arrow-right-thick ml-1"></i
                ></a>
              </p>
              <ul class="">
                <li class="mt-10 flex">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Unlimited API requests
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Unlimited number of projects
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Tokenizations and OAuth
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Unlimited number collaborators
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Same Day Support
                  </p>
                </li>
                <li class="mt-4 flex items-start">
                  <div class="flex-shrink-0 ">
                    <svg
                      class="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <p
                    class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200"
                  >
                    Cancel anytime
                  </p>
                </li>
              </ul>
            </div>
            <div class="w-full mt-20">
              <button
                class="shadow-2xl rounded font-bold hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full"
                style="background-color: #3d5af1"
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>
        <div class="text-center max-w-xl mx-auto">
          <p class="text-xs leading-tight roboto">
            *Prices are not to be taken as is and are subject to change in the
            future!
          </p>
        </div>
      </div>
    </div>

    <div class="flex mx-auto px-4 sm:px-6 lg:px-8 roboto">
      <div
        class="pricing-box mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex"
      >
        <div
          class="bg-white dark:bg-gray-800 px-6 py-8 lg:flex-shrink-1 lg:p-12"
        >
          <h3 
            class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9 dark:text-white"
          >
            No Strings Attached
          </h3>
          <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
            Start creating your APIs and connections without having to pay for anything :)
          </p>
          <div class="mt-8">
            <div class="flex items-center">
              <h4
                class="flex-shrink-0 pr-4 bg-white dark:bg-gray-800 text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600"
              >
                What&#x27;s included
              </h4>
              <div class="flex-1 border-t-2 border-gray-200"></div>
            </div>
            <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  10,000 API requests
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  5 projects
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  1 collaborator
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  No Tokenization
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                 Logs and History
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  1 environment 
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  Dashboard and Monitoring
                </p>
              </li>
            </ul>
          </div>
          <div class="mt-8">
            <div class="flex items-center">
              <h4
                class="flex-shrink-0 pr-4 bg-white text-sm dark:bg-gray-800 leading-5 tracking-wider font-semibold uppercase text-indigo-600"
              >
                &amp; What&#x27;s not
              </h4>
            </div>
            <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    class="h-6 w-6 mr-2"
                    fill="red"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  No Contracts. No monthly, setup, or additional payment
                  processor fees
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    class="h-6 w-6 mr-2"
                    fill="red"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </div>
                <p
                  class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200"
                >
                  No 2-week on-boarding, it takes 20 minutes!
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="py-8 px-6 text-center dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
          style="background-color: #3D5AF1"
        >
          <p class="text-xl leading-6 font-bold text-white dark:text-white">
            Free
          </p>
          <div
            class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-white dark:text-white"
          >
            <span> $0/mo </span>
          </div>
          <div class="mt-10">
            <div class="rounded-md shadow">
              <button
                type="button"
                class="py-2 px-4 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-black w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                style="background-color: #22D1EE"
              >
                Create your API
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
