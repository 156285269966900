import Vue from 'vue'
import Landing from './views/Landing.vue'
import Thanks from './views/Thank_You_Page.vue'
import ThanksJoin from './views/Thank_You_Page_Join_Us.vue'
import './tailwind.css'

import "@fortawesome/fontawesome-free/css/all.min.css";

Vue.config.productionTip = false

const routes = {
    '/': Landing,
    '/thanks': Thanks,
    '/thankjoin': ThanksJoin
}

new Vue({
    data: {
        currentRoute: window.location.pathname
    },
    computed: {
        ViewComponent() {
            return routes[this.currentRoute] || Landing
        }
    },
    render(h) { return h(this.ViewComponent) },
}).$mount('#app')