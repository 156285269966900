<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a href="/">
          <img
            class="w-20 leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
            src="../assets/TRANSPARANT.png"
          />
        </a>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        v-bind:class="{'hidden': !showMenu, 'block': showMenu}"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center ml-5">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-facebook text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2 roboto">Share</span></a
            >
          </li>
          <li class="flex items-center ml-5">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-twitter text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2 roboto">Tweet</span></a
            >
          </li>
          <li class="flex items-center ml-5">
            <button class="bg-black-600 text-white active:bg-gray-500 font-bold uppercase text-sm px-3 py-3 rounded outline-none focus:outline-none mr-1 mb-1 roboto" type="button" style="transition: all .15s ease">
                <i class="fas fa-sign-in-alt"></i> Log In
            </button>
          </li>
          <a href="#Subscribe_Section">
            <li class="flex items-center ml-5">
              <button class="bg-black-600 text-white active:bg-gray-500 font-bold uppercase text-sm px-3 py-3 rounded outline-none focus:outline-none mr-1 mb-1 roboto" type="button" style="transition: all .15s ease">
                <i class="fas fa-bell"></i> Subscribe
              </button>
            </li>
          </a>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>
