<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh; background-color: #0e153a"
      >
        <!-- <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style='background-image: url("../assets/img/");'
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div> -->
        <img
          class="absolute w-full h-max bg-cover"
          src="../assets/spiral.svg"
        />

        <div class="pt-40 container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="">
                <h1 class="text-white playfair font-semibold text-5xl">
                  Your story starts with Vrtex
                </h1>
                <p class="mt-5 mb-5 roboto text-lg text-gray-300">
                  You create the apps we worry about integrations and
                  infrastructure.
                </p>
                <!-- <button
                  class="mt-12 text-white active:bg-gray-600 font-bold uppercase text-sm px-20 py-5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  style="transition: all 0.15s ease; background-color: #3d5af1"
                >
                  Sign Up - It's Free
                </button> -->

                <div
                  class="emailoctopus-form-wrapper emailoctopus-form-default null"
                  style="
                    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                    color: rgb(26, 26, 26);
                  "
                >
                  <p class="emailoctopus-success-message"></p>
                  <p class="emailoctopus-error-message"></p>
                  <form
                    action="https://emailoctopus.com/lists/0c11aa2d-c18c-11eb-a3d0-06b4694bee2a/members/embedded/1.3/add"
                    method="post"
                    data-message-success="Thanks for subscribing!"
                    data-message-missing-email-address="Your email address is required."
                    data-message-invalid-email-address="Your email address looks incorrect, please try again."
                    data-message-bot-submission-error="This doesn't look like a human submission."
                    data-message-consent-required="Please check the checkbox to indicate your consent."
                    data-message-invalid-parameters-error="This form has missing or invalid fields."
                    data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later."
                    class="emailoctopus-form"
                  >
                    <!-- <input
                        id="field_0"
                        name="field_0"
                        type="email"
                        placeholder="email@example.com"
                        required="required"
                        class="mt-20 px-10 py-3 bg-gray-200 text-gray-800 rounded md:rounded-l-lg outline-none placeholder-gray-500 focus:bg-gray-100"
                      /> -->

                    <a href="http://eepurl.com/hljZMv" class="mt-5">
                      <button
                        type="button"
                        class="mt-5 roboto px-10 py-3 bg-gray-700 text-gray-100 font-semibold uppercase hover:bg-gray-600 rounded md:rounded-r-lg mt-5 md:mt-0"
                        style="transition: all 0.15s ease; background-color: #3d5af1; color: white;"
                      >
                        <div class="container flex">
                          <p class="px-3">Join Us</p>
                          <p class="px-3">🙌</p>
                        </div>
                      </button>
                    </a>

                    <div aria-hidden="true" class="emailoctopus-form-row-hp">
                      <input
                        type="text"
                        name="hpc4b27b6e-eb38-11e9-be00-06b4694bee2a"
                        tabindex="-1"
                        autocomplete="nope"
                      />
                    </div>
                    <div class="emailoctopus-form-row-subscribe">
                      <input
                        type="hidden"
                        name="successRedirectUrl"
                        value="https://vrtex.io/thankjoin"
                      />
                    </div>
                  </form>
                </div>

                <!-- <input
                  class="mt-20 px-10 py-3 bg-gray-200 text-gray-800 rounded-l-lg outline-none placeholder-gray-500 focus:bg-gray-100"
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                />
                <button
                  class="px-10 py-3 bg-gray-700 text-gray-100 font-semibold uppercase hover:bg-gray-600 rounded-r-lg"
                  style="transition: all 0.15s ease; background-color: #3d5af1"
                >
                  Join Us 🙌
                </button> -->
                <!-- <img class="absolute block max-w-none left-1/2 " style="top:-600px; transform:translateX(-275px);width:1242px !important; height:1414px !important;" src="./../assets/spiral.svg" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="pb-48 pt-100 -mt-24" style="background-color: #0e153a">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fab fa-connectdevelop text-4xl p-1"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl playfair font-semibold">
                    Integration-as-a-Service Platform
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600 roboto">
                    A space with all the tools you need to connect your apps to
                    the internet.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fas fa-magic text-2xl p-3"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl font-semibold playfair">
                    Automatic Code Generator
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600 roboto">
                    Fill in the form and the code is generated in the prefered
                    language.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fas fa-book-open text-2xl p-3"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl font-semibold playfair">
                    Automatic Documentation
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600 roboto">
                    No need to write documentation we handle that for you :)
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fas fa-cog text-2xl p-3"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl font-semibold playfair">
                    Automatic Cloud Deployment, Maintenance and Hosting
                  </h6>
                  <p class="mt-3 mb-4 text-gray-600 roboto">
                    Skip all those meetings on what to use and how to use.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fas fa-server text-2xl p-3"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl font-semibold playfair">
                    Serverless Architecture
                  </h6>
                  <p class="mt-4 mb-4 text-gray-600 roboto">
                    Any app you integrate will have a 99.999% uptime with the
                    benefits of serverles architectural mechanisms.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-15 h-15 mb-5 shadow-lg rounded-full"
                    style="background-color: #0e153a"
                  >
                    <i
                      class="fas fa-code-branch text-2xl p-3"
                      style="color: #22d1ee"
                    ></i>
                  </div>
                  <h6 class="text-xl font-semibold playfair">
                    Version Control
                  </h6>
                  <p class="mt-10 mb-4 text-gray-600 roboto">
                    Update your integrations without worrying about rollbacks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center pt-48 pb-28">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-black p-3 text-center inline-flex items-center justify-center w-20 h-20 mb-6 shadow-lg rounded-full"
                style="background-color: #0e153a"
              >
                <i
                  class="far fa-lightbulb text-5xl p-3"
                  style="color: #22d1ee"
                ></i>
              </div>
              <h3
                class="text-3xl text-white mb-2 font-semibold leading-normal playfair"
              >
                Start Solving Integration Challenges
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-white roboto"
              >
                The feeling of brainstorming and developing new software that
                solves real problems is the fuel of progress. This feeling is
                the reason the internet has seen an explosion of apps solving a
                magnitude of problems.
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-white roboto"
              >
                However, spending time on creating an app then spending more
                time marketing it, planning the business models talking to
                clients is already a lot. Adding another task of connecting it
                with other apps externally or internally is a time and resource
                consuming process one which Vrtex aims at eliminating
                <strong>100%</strong>.
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto text-center">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-black border-2 border-white"
                style="background-color: #22d1ee"
              >
                <img
                  alt="..."
                  src="../assets/img/Asset 110_Small.png"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="fill-current"
                      style="color: #e2f3f5"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-black text-left playfair">
                    Integration-as-a-Service
                  </h4>
                  <p
                    class="text-md font-light mt-2 text-black text-left roboto"
                  >
                    Connect your apps with the internet and show how great they
                    are or connect all your third party apps and supercharge
                    your company.
                  </p>
                  <a href="http://eepurl.com/hljZMv">
                    <button
                      class="mt-12 text-white active:bg-gray-600 font-bold uppercase text-sm px-10 py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 roboto"
                      type="button"
                      style="
                        transition: all 0.15s ease;
                        background-color: #3d5af1;
                      "
                    >
                      Sign Up - It's Free
                    </button>
                  </a>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-48 pt-28 -mt-24" style="background-color: #e2f3f5">
        <WorksComponent></WorksComponent>
      </section>
      <section class="relative pt-48 pb-48" style="background-color: #e2f3f5">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              style="color: #e2f3f5"
              class="fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="mx-auto px-4">
          <!-- <img 
          class="absolute w-full bg-auto z-5"
          src="../assets/dots.svg"
          style="z-index: -1;"> -->
          <div class="items-center flex flex-wrap">
            <div
              class="w-full md:w-5/12 lg:w-4/12 mx-auto px-4 relative text-center"
            >
              <div
                class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg z-10"
                style="background-color: #3d5af1"
              >
                <img
                  alt="..."
                  src="../assets/img/Asset 19_Small.png"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="fill-current"
                      style="color: #0e153a"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white text-left playfair">
                    Use Cases
                  </h4>
                  <p
                    class="text-md font-light mt-2 text-white text-left roboto"
                  >
                    Making an entire list of use cases would be exhaustive, but
                    these are a few of our favorite ones.
                  </p>
                  <button
                    class="mt-12 5text-black active:bg-gray-600 font-bold uppercase text-base px-10 py-4 md:px-5 md:py-2 lg:px-10 lg:py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 roboto"
                    type="button"
                    style="
                      transition: all 0.15s ease;
                      background-color: #22d1ee;
                    "
                  >
                    Sign Up - It's Free
                  </button>
                </blockquote>
              </div>
              <img
                alt="..."
                class="absolute hidden md:block lg:block"
                src="../assets/E0000_21.svg"
                style="left: 25rem; bottom: -5rem; width: 15rem; z-index: 3"
              />

              <img
                alt="..."
                class="absolute hidden md:block lg:block"
                src="../assets/E0000_18.svg"
                style="left: -3rem; top: -5rem; width: 15rem; z-index: 3"
              />
            </div>
            <div
              class="w-full md:w-6/12 mr-auto ml-auto text-center md:text-left"
            >
              <div class="flex flex-wrap lg:ml-24">
                <div class="w-full md:w-6/12 px-2">
                  <div class="relative flex flex-col mt-4">
                    <div class="px-4 py-5 flex-auto">
                      <div
                        class="text-gray-600 p-3 text-center inline-flex items-center justify-center mb-5 shadow-lg rounded-full"
                        style="
                          background-color: #0e153a;
                          width: 4rem;
                          height: 4rem;
                        "
                      >
                        <i
                          class="fas fa-sitemap text-xl"
                          style="color: #22d1ee"
                        ></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold playfair">
                        Business Automation
                      </h6>
                      <p class="mb-4 roboto text-gray-600">
                        Deliver business results faster and smarter.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex flex-col min-w-0">
                    <div class="px-4 py-5 flex-auto">
                      <div
                        class="text-gray-600 p-3 text-center inline-flex items-center justify-center mb-5 shadow-lg rounded-full"
                        style="
                          background-color: #0e153a;
                          width: 4rem;
                          height: 4rem;
                        "
                      >
                        <i
                          class="fas fa-drafting-compass text-xl"
                          style="color: #22d1ee"
                        ></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold playfair">
                        SaaS integration
                      </h6>
                      <p class="mb-4 text-gray-600 roboto">
                        Adopt best-of-breed SaaS applications
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-6/12 md:px-4 px-4">
                  <div class="relative flex flex-col min-w-0 mt-4">
                    <div class="px-4 py-5 flex-auto">
                      <div
                        class="text-gray-600 p-3 text-center inline-flex items-center justify-center mb-5 shadow-lg rounded-full"
                        style="
                          background-color: #0e153a;
                          width: 4rem;
                          height: 4rem;
                        "
                      >
                        <i
                          class="fas fa-newspaper text-xl"
                          style="color: #22d1ee"
                        ></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold playfair">
                        Legacy modernization
                      </h6>
                      <p class="mb-4 text-gray-600 roboto">
                        Transform systems from obstacle to advantage
                      </p>
                    </div>
                  </div>

                  <div class="relative flex flex-col min-w-0">
                    <div class="px-4 py-5 flex-auto">
                      <div
                        class="text-gray-600 p-3 text-center inline-flex items-center justify-center mb-5 shadow-lg rounded-full"
                        style="
                          background-color: #0e153a;
                          width: 4rem;
                          height: 4rem;
                        "
                      >
                        <i
                          class="fas fa-file-alt text-xl"
                          style="color: #22d1ee"
                        ></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold playfair">
                        Move to the cloud
                      </h6>
                      <p class="mb-4 text-gray-600 roboto">
                        Enable rapid, seamless cloud migration
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-40 relative" style="background-color: #e2f3f5">
        <div
          class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute"
          style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              style="color: #e2f3f5"
              class="fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto overflow-hidden pb-20">
          <div class="flex flex-wrap items-center pt-32">
            <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full"
                style="background-color: #3d5af1"
              >
                <i
                  class="fas fa-drafting-compass text-xl"
                  style="color: #22d1ee"
                ></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal playfair">
                Industries
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 roboto"
              >
                Endless possibilities for every verticle, for brevity sake these
                are a few of the industries that have seen more adoption over
                the rest.
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 roboto"
              >
                In order to check out more Industries click the button below.
              </p>
              <div class="block pb-6">
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Software Development</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Data as a Service</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Financial Services</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Manufacturing</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Higher Education</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Retail</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Media and Telecom</span
                ><span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2 roboto"
                  >Machine Learning</span
                >
              </div>
              <a
                class="font-bold text-gray-800 hover:text-gray-600"
                style="transition: all 0.15s ease 0s"
                href="#"
                ><button
                  class="mt-12 text-white active:bg-gray-600 font-bold uppercase text-sm px-10 py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 roboto"
                  type="button"
                  style="transition: all 0.15s ease; background-color: #0e153a"
                >
                  More Info
                  <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                </button>
              </a>
            </div>
            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div class="justify-center flex flex-wrap relative">
                <div class="my-4 w-full lg:w-6/12 px-4">
                  <div
                    class="shadow-lg rounded-lg text-center p-8"
                    style="background-color: #0e153a"
                  >
                    <div
                      class="shadow-md rounded-full w-12 h-12 mx-auto text-center p-3"
                      style="background-color: #3d5af1"
                    >
                      <i class="fas fa-layer-group" style="color: #22d1ee"></i>
                    </div>
                    <p class="text-lg text-white mt-4 font-semibold playfair">
                      Software Development
                    </p>
                    <p class="text-base text-white opacity-75 mt-2 roboto">
                      Stiching together backend communications with the front
                      end, generating automatic Documentation
                      <strng>eliminating 100%</strng> of the trivial development
                      tasks.
                    </p>
                  </div>
                  <div
                    class="shadow-lg rounded-lg text-center p-8 mt-8"
                    style="background-color: #0e153a"
                  >
                    <div
                      class="shadow-md rounded-full w-12 h-12 mx-auto text-center p-3"
                      style="background-color: #3d5af1"
                    >
                      <i class="fas fa-database" style="color: #22d1ee"></i>
                    </div>
                    <p class="text-lg text-white mt-4 font-semibold playfair">
                      Data as a Service
                    </p>
                    <p class="text-base text-white opacity-75 mt-2 roboto">
                      Oppening your Data sources to the internet or to
                      potentiall clients where every request is tracked and
                      secured through tokens.
                    </p>
                  </div>
                </div>
                <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  <div
                    class="shadow-lg rounded-lg text-center p-8"
                    style="background-color: #0e153a"
                  >
                    <div
                      class="shadow-md rounded-full w-12 h-12 mx-auto text-center p-3"
                      style="background-color: #3d5af1"
                    >
                      <i
                        class="fas fa-money-bill-wave"
                        style="color: #22d1ee"
                      ></i>
                    </div>
                    <p class="text-lg text-white mt-4 font-semibold playfair">
                      Financial
                    </p>
                    <p class="text-base text-white opacity-75 mt-2 roboto">
                      Making real time Fintech apps and software requires a
                      99.999% uptime service with the added functionality of
                      serverless scalability.
                    </p>
                  </div>
                  <div
                    class="shadow-lg rounded-lg text-center p-8 mt-8"
                    style="background-color: #0e153a"
                  >
                    <div
                      class="shadow-md rounded-full w-12 h-12 mx-auto text-center p-3"
                      style="background-color: #3d5af1"
                    >
                      <i class="fas fa-robot" style="color: #22d1ee"></i>
                    </div>
                    <p class="text-lg text-white mt-4 font-semibold playfair">
                      Machine Learning
                    </p>
                    <p class="text-base text-white opacity-75 mt-2 roboto">
                      Deploying a Machine LEarning model open for communication
                      with any third party app in order to predict different
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-24 relative block" style="background-color: #0e153a">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="fill-current"
              style="color: #0e153a"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-20">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white playfair">
                Who is this ment for?
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-500 roboto">
                Theoretically for anyone that is doing anything with technology
                - with some or even no knowledge in coding.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 pt-24 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 shadow-lg rounded-full inline-flex items-center justify-center"
                style="background-color: #22d1ee; height: 4rem; width: 4rem"
              >
                <i class="fas fa-code text-2xl" style="color: #3d5af1"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white playfair">
                Developer
              </h6>
              <p class="mt-2 mb-4 text-gray-500 roboto">
                Simplify API design and integration.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 shadow-lg rounded-full inline-flex items-center justify-center"
                style="background-color: #22d1ee; height: 4rem; width: 4rem"
              >
                <i class="fas fa-medal text-2xl" style="color: #3d5af1"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white playfair">
                Software Architect
              </h6>
              <p class="mt-2 mb-4 text-gray-500 roboto">
                Create a flexible, future-proof architecture.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 shadow-lg rounded-full inline-flex items-center justify-center"
                style="background-color: #22d1ee; height: 4rem; width: 4rem"
              >
                <i class="fas fa-poll text-2xl" style="color: #3d5af1"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white playfair">
                CDO
              </h5>
              <p class="mt-2 mb-4 text-gray-500 roboto">
                Bridge the gap between business and technology.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 shadow-lg rounded-full inline-flex items-center justify-center"
                style="background-color: #22d1ee; height: 4rem; width: 4rem"
              >
                <i class="fas fa-lightbulb text-2xl" style="color: #3d5af1"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white playfair">
                Machine Learning Practicioners
              </h5>
              <p class="mt-2 mb-4 text-gray-500 roboto">
                Deploy your ML model at low cost with great flexibility
                alongside 99.999% uptime.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block" style="background-color: #0e153a">
        <PricingComponent></PricingComponent>
      </section>
      <section class="relative block" style="background-color: #0e153a">
        <SubscribeComponent></SubscribeComponent>
      </section>
    </main>
    <footer-component class="pt-48"></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import PricingComponent from "../components/Pricing.vue";
import SubscribeComponent from "../components/Subscribe.vue";
import WorksComponent from "../components/Works.vue";
export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
    PricingComponent,
    SubscribeComponent,
    WorksComponent,
  },
};
</script>
