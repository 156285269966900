<template>
  <div
    id="Subscribe_Section"
    class="min-w-screen min-h-screen flex items-center justify-center py-5"
    style="background-color: #0e153a"
  >
    <div class="w-full px-5 py-16 md:py-24 text-white font-light">
      <div class="w-full max-w-6xl mx-auto pb-5">
        <div class="-mx-3 md:flex items-center">
          <div class="px-20 md:w-2/3 mb-10 md:mb-0">
            <h1
              class="text-6xl md:text-8xl font-bold mb-5 leading-tight roboto"
            >
              Stay <br class="hidden md:block" />updated.
            </h1>
            <h3 class="text-gray-600 mb-7 leading-tight roboto">
              Subscribe now and receive the latest updates.
            </h3>
            <div>
              <span
                class="inline-block w-40 h-1 rounded-full"
                style="background-color: #22d1ee"
              ></span>
              <span
                class="inline-block w-3 h-1 rounded-full ml-1"
                style="background-color: #22d1ee"
              ></span>
              <span
                class="inline-block w-1 h-1 rounded-full ml-1"
                style="background-color: #22d1ee"
              ></span>
            </div>
          </div>
          <div class="px-3 md:w-1/3">
            <link
              rel="stylesheet"
              href="https://emailoctopus.com/bundles/emailoctopuslist/css/1.6/form.css"
            />
            <div
              class="emailoctopus-form-wrapper emailoctopus-form-default null"
              style="
                font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                color: rgb(26, 26, 26);
              "
            >
              <p class="emailoctopus-success-message"></p>
              <p class="emailoctopus-error-message"></p>
              <form
                action="https://emailoctopus.com/lists/42cbcef0-98a7-11eb-a3d0-06b4694bee2a/members/embedded/1.3/add"
                method="post"
                data-message-success="Thanks for subscribing!"
                data-message-missing-email-address="Your email address is required."
                data-message-invalid-email-address="Your email address looks incorrect, please try again."
                data-message-bot-submission-error="This doesn't look like a human submission."
                data-message-consent-required="Please check the checkbox to indicate your consent."
                data-message-invalid-parameters-error="This form has missing or invalid fields."
                data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later."
                class="emailoctopus-form"
              >
                <!-- <div class="emailoctopus-form-row"> -->
                <!-- <label for="field_0"> Email address </label> -->
                <!-- <input
                    id="field_0"
                    name="field_0"
                    type="email"
                    placeholder="email@example.com"
                    required="required"
                    class="w-full rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black "
                  />
                </div> -->
                <div aria-hidden="true" class="emailoctopus-form-row-hp">
                  <input
                    type="text"
                    name="hpc4b27b6e-eb38-11e9-be00-06b4694bee2a"
                    tabindex="-1"
                    autocomplete="nope"
                  />
                </div>
                <div class="emailoctopus-form-row-subscribe">
                  <input
                    type="hidden"
                    name="successRedirectUrl"
                    value="https://vrtex.io/thanks"
                  />
                  <a href="http://eepurl.com/hljZMv">
                    <button
                      type="submit"
                      class="text-3xl block w-full hover:bg-indigo-700 focus:bg-indigo-700 transition-colors text-white rounded-lg font-semibold roboto"
                      style="background-color: #3d5af1; color: white"
                    >
                      <p class="py-3 px-3">Subscribe</p>
                    </button>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://emailoctopus.com/bundles/emailoctopuslist/js/1.6/form-embed.js"></script>

<script>
export default {
  data() {
    return {};
  },
};
</script>
