<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=PlayfairDisplay">
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
      <div
        class="pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh; background-color: #0E153A" 
      >
        <div class="container p-20 block space-y-10 mt-48 mb-48">
            <h1 class="block text-white text-4xl font-bold playfair">
                Thank you for joining our odyssey 🎉 
            </h1>
            <p class="block mt-5 text-white text-xl font-bold roboto">
              We are very excited to welcome you to the closed Beta of out platform. Our team will reach out to you shortly with an account. The account in question can be used to login
              and play around with the different functionalities that are currently online, so make sure to check that inbox 👀. We would appreciate feedback on how you are using
              the platform. Feel free to suggest edits and especially features. Once again thank you very much from the Vrtex team, we are lokking forward to seeing the amazing
              products you will build 🙌.
            </p>
            <p class="block mt-5 text-white text-xl font-bold roboto"> Here is a cookie 🍪 </p>
        </div>
      </div>
    </main>
    <footer-component class="pt-48"></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
