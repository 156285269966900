<template>
    <div class="w-full container mx-auto">
        <div class="w-full px-5 py-16 md:py-24">
         <div class="w-full max-w-6xl mx-auto">
           <div class="text-center max-w-xl mx-auto">
             <h1 class="text-6xl md:text-7xl font-bold mb-5 playfair">How it works.</h1>
             <h3 class="text-xl mb-5 font-light roboto">
               A couple clicks and you are all set.
             </h3>
             <div class="text-center mb-10">
               <span
                 class="inline-block w-1 h-1 rounded-full ml-1"
                 style="background-color: #3d5af1"
               ></span>
               <span
                 class="inline-block w-3 h-1 rounded-full ml-1"
                 style="background-color: #3d5af1"
               ></span>
               <span
                 class="inline-block w-40 h-1 rounded-full"
                 style="background-color: #3d5af1"
               ></span>
               <span
                 class="inline-block w-3 h-1 rounded-full ml-1"
                 style="background-color: #3d5af1"
               ></span>
               <span
                 class="inline-block w-1 h-1 rounded-full ml-1"
                 style="background-color: #3d5af1"
               ></span>
             </div>
           </div>
         </div>
       </div>
  <div class="flex pt-24">
    <div class="w-1/4">
      <div class="relative mb-2">
        <div class="w-20 h-20 mx-auto rounded-full text-lg text-white flex items-center" style="background-color: #3D5AF1">
          <span class="text-center text-white w-full">
            <i
                      class="fas fa-cubes text-2xl p-1"
                      style="color: #22d1ee"
                    ></i>
          </span>
        </div>
      </div>

      <h1 class="text-base font-bold text-center md:text-2xl mt-20 roboto">Define Parameters</h1>
    </div>

    <div class="w-1/4">
      <div class="relative mb-2">
        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(50% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
            <div class="w-0 py-1 rounded" style="width: 100%; background-color: #0E153A"></div>
          </div>
        </div>

        <div class="w-20 h-20 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center" style="background-color: #3D5AF1">
          <span class="text-center text-white w-full">
            <i
                      class="fas fa-user-edit text-2xl p-1"
                      style="color: #22d1ee"
                    ></i>
          </span>
        </div>
      </div>

      <h1 class="text-base font-bold text-center md:text-2xl mt-20 roboto">Review Code</h1>
    </div>

    <div class="w-1/4">
      <div class="relative mb-2">
        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(50% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
            <div class="w-0 bg-green-300 py-1 rounded" style="width: 100%; background-color: #0E153A"></div>
          </div>
        </div>

        <div class="w-20 h-20 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center" style="background-color: #3D5AF1">
          <span class="text-center text-gray-600 w-full">
            <i
                      class="fas fa-angle-double-up text-2xl p-1"
                      style="color: #22d1ee"
                    ></i>
          </span>
        </div>
      </div>

      <h1 class="text-base font-bold text-center md:text-2xl mt-20 roboto">Deploy</h1>
    </div>

    <div class="w-1/4">
      <div class="relative mb-2">
        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(50% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
            <div class="w-0 bg-green-300 py-1 rounded" style="width: 100%; background-color: #0E153A"></div>
          </div>
        </div>

        <div class="w-20 h-20 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center" style="background-color: #3D5AF1">
          <span class="text-center text-gray-600 w-full">
            <i
                      class="fas fa-chart-pie text-2xl p-1"
                      style="color: #22d1ee"
                    ></i>
          </span>
        </div>
      </div>

      <h1 class="text-base font-bold text-center md:text-2xl mt-20 roboto">Monitor</h1>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>